/* * {
  background-color: black;
  color: rgb(231, 231, 231);
} */

body {
  text-align: center;
  font-family: sans-serif;
}

img {
  max-width: 90%;
  padding: 0 15px 0 15px;
}

.toggleContainer {
  box-sizing: border-box;
  width: 100%;
  margin: 75px auto;
  display: flex;
  height: 65px;
  border: 3px solid black;
}

.toggleButton {
  width: 50%;
  height: 100%;
}

.lightButton {
  background-color: #fff !important;
}

.darkButton {
  background-color: black !important;
}

@media screen and (min-width: 800px) {
  img {
    max-width: 70%;
  }
}
